@import "customs";
@import "responsive";

a {
  color: #32be8f;
  &:hover {
    color: #38d39f;
  }
}

.text-primary {
  color: #32be8f;
}

.text-gray {
  color: #181818;
}
.bg-gray {
  background-color: #232323;
}

.btn-primary {
  color: white;
  background-color: #32be8f;
  &:hover {
    background-color: #38d39f;
  }
}

.rounded-md-custom {
  border-radius: 18px;
}

body {
  font-family: "Poppins", sans-serif;
}

ul,
li {
  list-style-type: none;
}

.border-6 {
  border-width: 6px;
}
.border-5 {
  border-width: 5px;
}

.border-t-3 {
  border-top-width: 3px;
}
