//input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input {
  -webkit-appearance: none;
}

input,
textarea,
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    border-color: #32be8f;
  }
}

//loading
.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #c5ff79;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  bottom: 48%;
  right: 48%;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
